import React, { useState } from 'react';
import "./App.css";
import faq from "./img/faq.jpg";
const FAQ = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  // Function to toggle questions
  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "Lorem ipsum dolor, sit amet",
      answer: "Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet"
    },
    {
        question: "Lorem ipsum dolor, sit amet",
        answer: "Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet"    },
    {
        question: "Lorem ipsum dolor, sit amet",
        answer: "Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet"    },
    {
      question: "Lorem ipsum dolor, sit amet",
      answer: "Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet Lorem ipsum dolor, sit amet"
    }
  ];

  return (
    <section className="bg-gray-50 py-10">
      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8">
        
        {/* FAQ Section (First Column) */}
        <div>
          <h2 className="text-3xl font-bold mb-4">Frequently Asked Questions</h2>
          <p className="mb-6 text-gray-600">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste
                sit, animi, qui fugiat sequi tenetur unde dolorum odio maxime
                porro dicta! Magni nesciunt ipsam nobis expedita asperiores
                pariatur beatae dolores?
          </p>

          {/* List of FAQs */}
          <div className="space-y-4">
            {faqData.map((item, index) => (
              <div key={index} className="border-red-200 pb-4 border-solid border-2 rounded-lg">
                <button
                  className="w-full text-left flex justify-between items-center text-lg font-medium text-gray-700 hover:text-gray-900 focus:outline-none"
                  onClick={() => toggleQuestion(index)}
                >
                  {item.question}
                  <span>{activeQuestion === index ? '-' : '+'}</span>
                </button>
                {activeQuestion === index && (
                  <p className="mt-2 text-gray-600">{item.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Image Section (Second Column) */}
        <div className="flex justify-center items-center">
          <img
            src={faq}
            alt="FAQ Image"
            className="rounded-lg shadow-md"
          />
        </div>
      </div>
    </section>
  );
};

export default FAQ;
