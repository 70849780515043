//import logo from './logo.svg';
import './App.css';
//import MenuNav from './MenuNav';
//import Navbar from './Navbar';
import Nabar from './Nabar';
import Hero from './Hero';
import Partners from './Partners';
import Card from './Card';
import AboutUs from './AboutUs';
import Protests from './Protests';
import Detainees from './Detainees';
import Testimony from './Testimony';
import Blog from './Blog';
//import Faq from './Faqcom';
import Faq from './Faq';
import Footer from './Footer';
function App() {
  return (
    <div className="App">
      <Nabar/>
      <Hero/>
      <Partners/>
      <Card/>
      <AboutUs/>
      <Protests/>
      <Detainees/>
      <Testimony/>
      <Blog/>
      <Faq/>
      <Footer/>
    </div>
  );
}

export default App;
