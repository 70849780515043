import React from "react";
import "./App.css";
import pix from "./img/pix.jpg";
import pix1 from "./img/pix.jpg";
import pix2 from "./img/pix2.jpg";
import pix3 from "./img/pix3.jpg";
import orange from "./img/orange.jpg";
import heroimg from "./img/heroimg.png";

function Hero() {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between py-16">
        <div className="md:w-1/2 space-y-6 p-6">
          <h4 className="text-2xl font-bold text-red-600" id="home">
            Lorem Ipsum
          </h4>
          <h1 className="text-5xl font-bold text-gray-900">
            Protest effort Make Change even
          </h1>
          <p className="text-gray-900">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta
            accusamus officiis laboriosam optio maiores ab saepe illum
            reiciendis! Nostrum laudantium beatae incidunt dolorem ullam,
            consequuntur reprehenderit nisi quas pariatur consequatur..
          </p>
          <div className="flex p-5">
            <div>
              <button className="bg-red-600 text-white px-6 py-3 rounded-full hover:bg-red-500 focus:outline-none">
                Donate Now
              </button>
            </div>
            <div className="relative mt-1 overlapping-images p-7">
              <img src={pix2} alt="Image 1" className="img1" />
              <img src={pix} alt="Image 2" className="img2" />
              <img src={pix3} alt="Image 3" className="img3" />
              <img src={orange} alt="Image 3" className="img4" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <div className="relative mb-12 px-3 lg:mb-0">
              <h5 className="text-5xl font-bold text-gray-900">12+</h5>
              <h6 className="mb-0 font-normal dark:text-neutral-50">
                Protest Tracked
              </h6>
              <div className="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400 lg:block"></div>
            </div>
            <div className="relative mb-12 px-3 lg:mb-0">
              <h5 className="text-5xl font-bold text-gray-900">500+</h5>
              <h6 className="mb-0 font-normal dark:text-neutral-50">
                Legal Cases Filed
              </h6>
              <div className="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400 lg:block"></div>
            </div>
            <div className="relative mb-12 px-3 lg:mb-0">
              <h5 className="text-5xl font-bold text-gray-900">140+</h5>
              <h6 className="mb-0 font-normal dark:text-neutral-50">
                Justice Delivered
              </h6>
            </div>
          </div>
        </div>

        <div className="md:w-1/2 p-6">
          <img
            src={heroimg}
            alt="hero image"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  );
}
export default Hero;
