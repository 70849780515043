import React from "react";
import "./App.css";

import protest from "./img/protest.jpg";


function Protests() {
  return (
    <div>
      <section className="py-12">
        <div className="container mx-auto px-4">
          <div>
            <h2 className="text-2xl font-semibold text-center mb-6 text-red-600">
              Lorem Ipsum
            </h2>
            <h2
              className="text-3xl font-bold text-center mb-6 text-black-600"
              id="protest"
            >
              Protests
            </h2>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div
              className="w-full md:w-2/3 h-96 relative bg-cover bg-center"
              style={{backgroundImage: `url(${protest})`}}>
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="text-left text-white absolute bottom-10 left-0 text-black p-2">
                  <div className="flex">
                    <div className="flex-item">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path
                          fill-rule="evenodd"
                          d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="flex-item">
                      <p className="text-lg mb-4">
                        Lagos, Ogun, Kano & 4 other States.
                      </p>
                    </div>
                  </div>

                  <h2 className="text-2xl font-bold mb-4">
                    End Bad Governemnt
                  </h2>
                </div>
                <div className="flex text-center absolute bottom-0 text-black p-2 gap-1">
                  <div className="flex-item">
                    <hr className="w-10 h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
                  </div>
                  <div className="flex-item">
                    <hr className="w-7 h-1 my-8 bg-red-500 border-0 rounded dark:bg-gray-700" />
                  </div>
                  <div className="flex-item">
                    <hr className="w-10 h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 h-96 bg-red-500 text-white p-8 flex flex-col justify-between">
              <div className="flex flex-col items-center text-center mb-4">
                <div className="flex flex-row">
                  <div className="flex-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-14a6 6 0 110 12A6 6 0 0110 4zm.5 3a.5.5 0 00-1 0v4.25l3.5 2.1a.5.5 0 00.5-.85l-3-1.8V7z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
                <div className="flex-item">
                  <h2 className="text-1xl">652 Days Old</h2>
                </div>

                <h3 className="text-2xl font-semibold text-white">
                  End Bad Governemnt Protest
                </h3>
                <p className="text-white mt-2">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Harum provident saepe, nemo iusto commodi quisquam voluptates
                  corrupti inventore explicabo in id ipsam, nobis suscipit! Ipsa
                  architecto modi atque illo consequatur?
                </p>
              </div>

              <div className="grid grid-cols-2 gap-20">
                <div className="bg-red-400 p-4 rounded-lg text-center">
                  <h4 className="text-xl font-bold text-white-700">25</h4>
                  <p className="text-white-500">Detainees</p>
                </div>

                <div className="bg-red-400 p-4 rounded-lg text-center">
                  <h4 className="text-xl font-bold text-white-700">15</h4>
                  <p className="text-white-500">Released</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Protests;
