import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import blog from "./img/blog.jpg";

function Blog() {
  return (
    <div>
      <section className="bg-white py-8">
        <div className="container mx-auto px-4">
          <div>
            <h2 className="text-2xl font-semibold text-center mb-6 text-red-600">
              Blog
            </h2>
            <h2
              className="text-3xl font-bold text-center mb-6 text-black-600"
              id="blog"
            >
              Legal Tips
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="border rounded-lg p-4">
              <img
                src={blog}
                alt="Article Image"
                className="w-full h-48 object-cover rounded-md mb-4"
              />
              <div className="flex gap-1">
                <div>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-width="2"
                      d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </div>
                <div className="text-sm text-gray-500">By: admin</div>
              </div>
              <h3 className="text-3xl font-semibold mt-2">
                Lorem ipsum dolor sit amet, consectetur
              </h3>
              <p className="text-gray-600 mt-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent viverra nunc ac nibh placerat...
              </p>
              <button className="mt-4 border-2 border-red-500 text-red-500 px-4 py-2 rounded hover:bg-red-500 hover:text-white transition duration-300">
                Read More
              </button>
            </div>

            <div className="border rounded-lg p-4">
              <img
                src={blog}
                alt="Article Image"
                className="w-full h-48 object-cover rounded-md mb-4"
              />
              <div className="flex gap-1">
                <div>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-width="2"
                      d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </div>
                <div className="text-sm text-gray-500">By: admin</div>
              </div>
              <h3 className="text-3xl font-semibold mt-2">
                Lorem ipsum dolor sit amet, consectetur
              </h3>
              <p className="text-gray-600 mt-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent viverra nunc ac nibh placerat...
              </p>
              <button className="mt-4 border-2 border-red-500 text-red-500 px-4 py-2 rounded hover:bg-red-500 hover:text-white transition duration-300">
                Read More
              </button>
            </div>

            <div className="border rounded-lg p-4">
              <img
                src={blog}
                alt="Article Image"
                className="w-full h-48 object-cover rounded-md mb-4"
              />
              <div className="flex gap-1">
                <div>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-width="2"
                      d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </div>
                <div className="text-sm text-gray-500">By: admin</div>
              </div>
              <h3 className="text-3xl font-semibold mt-2">
                Lorem ipsum dolor sit amet, consectetur
              </h3>
              <p className="text-gray-600 mt-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent viverra nunc ac nibh placerat...
              </p>
              <button className="mt-4 border-2 border-red-500 text-red-500 px-4 py-2 rounded hover:bg-red-500 hover:text-white transition duration-300">
                Read More
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Blog;
