import React from "react";
import "./App.css";
import { Link } from "react-router-dom";

function Card() {
  return (
    <div>
      <section className="bg-gray-100 py-8">
        <div className="container mx-auto px-4">
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <div className="bg-black text-white p-6 rounded-lg relative flex flex-col items-center justify-center">
              <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v8m4-4H8"
                  />
                </svg>
              </div>

              <h3 className="text-lg font-semibold mb-2">Lorem ipsum</h3>
              <p className="text-sm text-center mb-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
                similique nisi laboriosam molestias iusto, doloribus voluptates
                consequuntur earum nam libero, sit.
              </p>
              <Link to="/"
                className="flex items-center space-x-2 text-sm text-white hover:none"
              >
                <span>Read More</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </Link>

              <h1 className="absolute bottom-4 right-4 text-8xl font-bold opacity-30">
                01
              </h1>
            </div>

            <div className="bg-white p-6 rounded-lg relative flex flex-col items-center justify-center shadow-md">
              <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-red-600 size-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.47 2.47a.75.75 0 0 1 1.06 0l6 6a.75.75 0 0 1 0 1.06l-6 6a.75.75 0 1 1-1.06-1.06l4.72-4.72H9a5.25 5.25 0 1 0 0 10.5h3a.75.75 0 0 1 0 1.5H9a6.75 6.75 0 0 1 0-13.5h10.19l-4.72-4.72a.75.75 0 0 1 0-1.06Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>

              <h3 className="text-lg font-semibold mb-2">Lorem ipsum</h3>
              <p className="text-sm text-center mb-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
                similique nisi laboriosam molestias iusto, doloribus voluptates
                consequuntur earum nam libero, sit.
              </p>
              <Link to="/"
                className="flex items-center space-x-2 text-sm text-black-500 hover:none"
              >
                <span>Read More</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </Link>

              <h1 className="absolute bottom-4 right-4 text-8xl font-bold text-gray-200 opacity-30">
                02
              </h1>
            </div>

            <div className="bg-white p-6 rounded-lg relative flex flex-col items-center justify-center shadow-md">
              <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-red-600 size-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.75 3.375c0-1.036.84-1.875 1.875-1.875H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375Zm10.5 1.875a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25ZM12 10.5a.75.75 0 0 1 .75.75v.028a9.727 9.727 0 0 1 1.687.28.75.75 0 1 1-.374 1.452 8.207 8.207 0 0 0-1.313-.226v1.68l.969.332c.67.23 1.281.85 1.281 1.704 0 .158-.007.314-.02.468-.083.931-.83 1.582-1.669 1.695a9.776 9.776 0 0 1-.561.059v.028a.75.75 0 0 1-1.5 0v-.029a9.724 9.724 0 0 1-1.687-.278.75.75 0 0 1 .374-1.453c.425.11.864.186 1.313.226v-1.68l-.968-.332C9.612 14.974 9 14.354 9 13.5c0-.158.007-.314.02-.468.083-.931.831-1.582 1.67-1.694.185-.025.372-.045.56-.06v-.028a.75.75 0 0 1 .75-.75Zm-1.11 2.324c.119-.016.239-.03.36-.04v1.166l-.482-.165c-.208-.072-.268-.211-.268-.285 0-.113.005-.225.015-.336.013-.146.14-.309.374-.34Zm1.86 4.392V16.05l.482.165c.208.072.268.211.268.285 0 .113-.005.225-.015.336-.012.146-.14.309-.374.34-.12.016-.24.03-.361.04Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>

              <h3 className="text-lg font-semibold mb-2">Lorem ipsum</h3>
              <p className="text-sm text-center mb-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
                similique nisi laboriosam molestias iusto, doloribus voluptates
                consequuntur earum nam libero, sit.
              </p>
              <Link to="/"
                className="flex items-center space-x-2 text-sm text-black-500 hover:none"
              >
                <span>Read More</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </Link>

              <h1 className="absolute bottom-4 right-4 text-8xl font-bold text-gray-200 opacity-30">
                03
              </h1>
            </div>

            <div className="bg-white p-6 rounded-lg relative flex flex-col items-center justify-center shadow-md">
              <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  className="text-red-600 w-6 h-6 size-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
                </svg>
              </div>

              <h3 className="text-lg font-semibold mb-2">Lorem ipsum</h3>
              <p className="text-sm text-center mb-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
                similique nisi laboriosam molestias iusto, doloribus voluptates
                consequuntur earum nam libero, sit.
              </p>
              <Link to="/"
                className="flex items-center space-x-2 text-sm text-black-500 hover:none"
              >
                <span>Read More</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </Link>

              <h1 className="absolute bottom-4 right-4 text-8xl font-bold text-gray-200 opacity-30">
                04
              </h1>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Card;
