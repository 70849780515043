import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import detainees from "./img/detainees.jpg";

function Detainees() {
  return (
    <div>
      <section className="bg-gray-50 py-8">
        <div className="container mx-auto px-4">
          <div>
            <h2
              className="text-2xl font-semibold text-center mb-6 text-red-600"
              id="detainees"
            >
              Detainees
            </h2>
            <h2 className="text-3xl font-bold text-center mb-6 text-black-600">
              Lorem Ipsum
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="md:col-span-2 flex flex-col space-y-0">
              <div>
                <img
                  src={detainees}
                  alt="Placeholder Image"
                  className="w-full h-auto shadow-lg"
                />
              </div>

              <div className="bg-red-500 text-white p-6 flex justify-between items-center">
                <p className="text-3xl">
                  Gee our Lasalle ran great. Those were the days and you know
                  where then we could use a man....
                </p>
                <button className="bg-white text-red-500 py-2 px-4 rounded hover:bg-orange-100">
                  Learn More
                </button>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold mb-4">
                Search for protesters remanded in prison here
              </h2>
              <p className="text-gray-600 mb-4">
                Find the information you're looking for in our records.
              </p>

              <div className="mb-4">
                <input
                  id="searchInput"
                  type="text"
                  placeholder="Search..."
                  className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-orange-200"
                />
              </div>
              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs border-b text-gray-700 bg-white-100 dark:bg-white-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-4 py-2 rounded-s-lg">
                        All
                      </th>
                      <th scope="col" className="px-4 py-2">
                        Detainees
                      </th>
                      <th scope="col" className="px-4 py-2 rounded-e-lg">
                        Released
                      </th>
                      <th scope="col" className="px-4 py-2 rounded-e-lg">
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white dark:bg-white-800">
                      <th
                        scope="row"
                        className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <div className="flex gap-1">
                          <div>
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-width="2"
                                d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>
                          </div>
                          <div>John Doe</div>
                        </div>
                      </th>
                      <td>&nbsp;</td>
                      <td className="px-4 py-2">
                        <button className="text-red-500 py-2 px-4 rounded bg-red-100">
                          Detained
                        </button>
                      </td>
                      <td className="px-4 py-2">
                        <button className="text-sm text-red-500 py-2 px-4 rounded bg-red-100">
                          Learn more
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <div className="flex gap-1">
                          <div>
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-width="2"
                                d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>
                          </div>
                          <div>John Doe</div>
                        </div>
                      </th>
                      <td>&nbsp;</td>
                      <td className="px-4 py-2">
                        <button className="text-red-500 py-2 px-4 rounded bg-red-100">
                          Detained
                        </button>
                      </td>
                      <td className="px-4 py-2">
                        <button className="text-sm text-red-500 py-2 px-4 rounded bg-red-100">
                          Learn more
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <div className="flex gap-1">
                          <div>
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-width="2"
                                d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>
                          </div>
                          <div>John Doe</div>
                        </div>
                      </th>
                      <td>&nbsp;</td>
                      <td className="px-4 py-2">
                        <button className="text-green-500 py-2 px-4 rounded bg-green-100">
                          Released
                        </button>
                      </td>
                      <td className="px-4 py-2">
                        <button className="text-sm text-red-500 py-2 px-4 rounded bg-red-100">
                          Learn more
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <div className="flex gap-1">
                          <div>
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-width="2"
                                d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>
                          </div>
                          <div>John Doe</div>
                        </div>
                      </th>
                      <td>&nbsp;</td>
                      <td className="px-4 py-2">
                        <button className="text-green-500 py-2 px-4 rounded bg-green-100">
                          Released
                        </button>
                      </td>
                      <td className="px-4 py-2">
                        <button className="text-sm text-red-500 py-2 px-4 rounded bg-red-100">
                          Learn more
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Detainees;
