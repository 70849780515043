import React, { useState } from 'react';
import "./App.css";
//import { Link } from "react-router-dom";
import logo from "./img/logo.png";

const Nabar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        
        {/* Logo Section (First Column) */}
        <div className="flex-shrink-0">
          <a href="/" className="text-2xl font-bold">
            <img src={logo} alt="Logo" className="h-10 w-auto" />
          </a>
        </div>

        {/* Menu and Search Bar (Second Column) */}
        <div className="hidden md:flex border bg-gray-100 rounded-full px-4 py-2 focus:outline-none focus:border-orange-500">
            <div className="hidden md:flex md:flex-grow justify-center items-center space-x-6">
            <a href="#home" className="text-gray-600 hover:text-gray-900">Home</a>
            <a href="#about" className="text-gray-600 hover:text-gray-900">About</a>
            <a href="#protest" className="text-gray-600 hover:text-gray-900">Protests</a>
            <a href="#detainees" className="text-gray-600 hover:text-gray-900">Detainees</a>
            <a href="#blog" className="text-gray-600 hover:text-gray-900">Legal Tips</a>
            <a href="#contact" className="text-gray-600 hover:text-gray-900">Contact</a>

            {/* Search Bar */}
            <div className="relative">
                <input
                type="text"
                placeholder="Search"
                className="border border-gray-300 rounded-full py-2 px-4 text-sm focus:outline-none focus:border-red-500"
                />
            </div>
            </div>
        </div>

        {/* Donate Button (Third Column) */}
        <div className="hidden md:flex">
          <button className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 focus:outline-none">
            Donate
          </button>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            className="text-gray-600 hover:text-gray-900 focus:outline-none"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="md:hidden bg-white">
          <div className="px-4 pt-4 pb-2 space-y-2">
            <a href="#home" className="block text-gray-600 hover:text-gray-900">Home</a>
            <a href="#about" className="block text-gray-600 hover:text-gray-900">About</a>
            <a href="#protest" className="block text-gray-600 hover:text-gray-900">Protests</a>
            <a href="#detainees" className="block text-gray-600 hover:text-gray-900">Detainees</a>
            <a href="#blog" className="block text-gray-600 hover:text-gray-900">Legal Tips</a>
            <a href="#contact" className="block text-gray-600 hover:text-gray-900">Contact</a>
          </div>

          <div className="px-4 py-2">
            <input
              type="text"
              placeholder="Search"
              className="w-full border border-red-300 rounded-full py-2 px-4 text-sm focus:outline-none focus:border-red-500"
            />
          </div>

          <div className="px-4 py-4">
            <button className="bg-red-500 text-white w-full px-4 py-2 rounded-full hover:bg-red-600 focus:outline-none">
              Donate
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Nabar;
