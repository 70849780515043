import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import p1 from "./img/p1.PNG";
import p2 from "./img/p2.PNG";
import p3 from "./img/p3.PNG";
import p4 from "./img/p4.PNG";


function Partners() {
  return (
    <div>
      <section class="bg-gray-50 py-8">
        <div class="container mx-auto px-4">
          <div class="border border-gray-300 rounded-lg p-6">
            <h2 class="text-2xl font-semibold text-center mb-6 text-red-600">
              Our Partners
            </h2>

            <div class="grid grid-cols-2 md:grid-cols-4 gap-4 items-center">
              <div class="flex justify-center">
                <img src={p1} alt="Partner 1" />
              </div>
              <div class="flex justify-center">
                <img src={p2} alt="Partner 2" />
              </div>
              <div class="flex justify-center">
                <img src={p3} alt="Partner 3" />
              </div>
              <div class="flex justify-center">
                <img src={p4} alt="Partner 4" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Partners;
