import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import pix from "./img/pix.jpg";
import testimony from "./img/testimony.jpg";


function Testimony() {
  return (
    <div>
      <section className="bg-gray-100 py-8">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h2 className="text-2xl font-semibold mb-6 text-red-600">
                Testimony
              </h2>
              <h2 className="text-4xl font-bold mb-4">
                Lorem ipsum dolor sit amet consectetur. Eu
              </h2>
              <p className="text-gray-600">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. At
                earum nihil incidunt, voluptatem veritatis
              </p>
            </div>

            <div className="relative">
              <img
                src={testimony}
                alt="Client Image"
                className="w-full h-auto rounded-lg shadow-lg"
              />

              <div className="absolute top-8 right-0 bg-white p-4 rounded-lg shadow-lg w-64">
                <div className="flex items-center mb-2">
                  <span className="text-orange-400 text-lg">
                    &#9733;&#9733;&#9733;&#9733;&#9734;
                  </span>
                  <span className="ml-2 text-gray-600">4.0</span>
                </div>

                <p className="text-gray-700 mb-4">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Temporibus eum rerum eveniet natus saepe. Optio omnis aliquam
                  sunt beatae excepturi.
                </p>

                <div className="flex items-center">
                  <img
                    src={pix}
                    alt="User"
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <div>
                    <p className="font-semibold text-gray-800">Sandra Bolt</p>
                    <p className="text-sm text-gray-500">Manager</p>
                    <h1 className="absolute bottom-4 right-4 text-8xl font-bold opacity-30">
                      <svg
                        className="w-12 h-12 text-gray-700 dark:text-gray-300"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 14"
                      >
                        <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                      </svg>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Testimony;
