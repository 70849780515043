import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import aboutimg from './img/aboutimg.png';

function AboutUs() {
  return (
    <div>
       <section className="bg-gray-100">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between py-16">
          
          <div className="md:w-1/2 space-y-4 p-6" id="about">
              <h4 className="text-2xl font-bold text-red-600">About Us</h4>
              <h1 className="text-5xl font-bold text-gray-900">Helping People in Need Around The World</h1>
              <p className="text-gray-900">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta accusamus officiis laboriosam optio maiores ab saepe illum reiciendis! Nostrum laudantium beatae incidunt dolorem ullam, consequuntur reprehenderit nisi quas pariatur consequatur..</p>
              <div className="flex gap-4">
                <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center mb-4">
                  <svg className="w-6 h-6 text-black-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v8m4-4H8" />
                  </svg>              
                </div>
                  <div>
                      <h3 className="text-1xl font-bold">Legal support</h3>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore non blanditiis amet molestias maxime.</p>
                  </div>
              </div> 
              <div className="flex gap-4">
                <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center mb-4">
                  <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-width="2" d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                  </svg>                               
                </div>
                  <div>
                      <h3 className="text-1xl font-bold">Volunteer</h3>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore non blanditiis amet molestias maxime.</p>
                  </div>
              </div>  
              <div className="m-10">
                 <button className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-orange-500 focus:outline-none">Learn More</button>
              </div>     
          </div>

         
          <div className="md:w-1/2 p-6">
              <img src={aboutimg} alt="About image" className="rounded-lg shadow-lg"/>
          </div>
      </div>
  </section>
    </div>
  );
}
export default AboutUs;
